import LoadingDots from "../components/general/LoadingDots";
import { Problem } from "./TreePage";
import { motion } from "framer-motion";

import "../styles/searchPage.css"
import { useFormHandler } from "../scripts/hooks/useFormHandler";
import { useEffect, useState } from "react";
import SearchResult from "../components/SearchPage/SearchResult";

interface Props {
  problems: Problem[] | undefined;
}

function ProblemSearchPage({problems}: Props) {

    let {formData, formChange} = useFormHandler({
        query: ""
    }, undefined, false)

    let [filteredProblems, setFilteredProblems] = useState<Problem[]>([])

    useEffect(() => {

        if (formData.query) {
            let q = formData.query.toUpperCase()
            setFilteredProblems(problems?.filter(p => {
                return p.title.toUpperCase().includes(q) ||
                    p.content.toUpperCase().includes(q)
            }) || [])
        } else {
            setFilteredProblems([])
        }

    }, [formData])

    return ( 
        <motion.div
      key="searchPage"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
    >
      {problems ? (
        <>
          {!problems.length ? (
            <h2>Pro tento ročník nejsou úlohy dostupné</h2>
          ) : (
            <div className="searchPage centerAlign column">
              
                <input className="searchBar" type="text" id="query" onChange={formChange}/>

                <div className="searchResultContainer centerAlign column">
                    {filteredProblems.length ? filteredProblems.map(p => <SearchResult problem={p}/>) : <>{formData.query ? <h3>Pro tento výraz nebyly nalezeny žádné úlohy :(</h3> : <h3>Zadej prosím výraz pro vyhledání úlohy</h3>}</>}
                </div>

            </div>
          )}
        </>
      ) : (
        <LoadingDots />
      )}
    </motion.div>
     );
}

export default ProblemSearchPage;