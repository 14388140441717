import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { getProblems } from '../scripts/dataHandler';

import TreePage from '../pages/TreePage';
import ProblemInfoPage from './ProblemInfoPage';
import ProblemSearchPage from './ProblemSearchPage';

function MainPage() {

    let location = useLocation()
    let [searchParams] = useSearchParams()

    let [problems, setProblems]: any = useState(undefined)

    useEffect(() => {
      getProblems(setProblems)
    },[])


    return ( 
       <div className='container'>

        <div className='centerAlign column'>

        <div className={`title fadeInBottom ${location.pathname !== "/" ? "top" : ""}`}>
          <Link to="/">
            <h1>Archiv soutěže</h1>
            <h1 className='logo'><i>ŠM</i>ou<i>L</i>a</h1>
          </Link>

          <div className='menu'>
            
            <Link to="/archiv?rocnik=1">
              <h2>Úlohy</h2>
            </Link>

          </div>
        </div>

        <div className='pageContent'> {/* bootleg ahh router*/}
        <AnimatePresence>

            {location.pathname === "/" && <motion.div
                key="basic"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: {duration: 0.9}}}/> }

            {location.pathname === "/archiv" && 
            <>
              <TreePage problems={problems ? problems.filter((p: any) => p.season === Number(searchParams.get("rocnik"))) : undefined}/> {/* uuuh typing */}
            </>
            }

            {location.pathname === "/uloha" && 
            <>
              <ProblemInfoPage problem={problems ? (problems.find((p: any) => p.id === Number(searchParams.get("id"))) || {title: "err"}) : undefined}/> {/* uuuh typing */}
            </>
            }

            {location.pathname === "/vyhledat" &&
            <>
              <ProblemSearchPage problems={problems}/>
            </>
            }

        </AnimatePresence>
        </div>

            <h3 className='footer'>© ŠMouLa {new Date().getFullYear()}</h3>

      </div>

      </div>
     );
}

export default MainPage;