import { Link } from "react-router-dom";
import { Problem } from "../../pages/TreePage";
import { useState, useEffect } from "react";

interface Props {
    problem: Problem
}

const useIsMobile = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
  
    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth < breakpoint);
      window.addEventListener("resize", handleResize);
      
      return () => window.removeEventListener("resize", handleResize);
    }, [breakpoint]);
  
    return isMobile;
  };

function SearchResult({problem}: Props) {
    const isMobile = useIsMobile();

    const getDesc = (text: string) => {
        if (text && text.length > 300) {
            if (isMobile) {
                return text.slice(0,69) + "..."
            } else {
            return text.slice(0,300) + "..."
            }
        } else if (!text || text === "") {
            return "xxx"
        } else {return text}
    }

    return ( 
        <Link to={`/uloha?id=${problem.id}`}>
            <div className="searchResult column">
                <h2 style={{margin: "0", marginBottom: "20px"}}>{`${problem.title} (Sezóna ${problem.season})`}</h2>
                <p dangerouslySetInnerHTML={{__html: getDesc(problem.content)}}></p>
            </div>
        </Link>
     );
}

export default SearchResult;