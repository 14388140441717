import React from 'react';

interface Props {
  name: string; // The name of the icon, e.g., "home-outline".
  size: string; // Size of the icon (e.g., "24px", "32px").
  color: string;
}

const Icon: React.FC<Props> = ({ name, size, color }) => {
  return (
    <div className="icon hoverPointer" style={{ width: size, height: size, color: color }}>
      <ion-icon
        name={name}
        style={{
          fontSize: size,
          display: 'block', // Ensures proper alignment.
          color: color,
        }}
      ></ion-icon>
    </div>
  );
};

export default Icon;