import { motion } from "framer-motion";

import Line from "../components/TreePage/Line";
import TreeNode from "../components/TreePage/TreeNode";

import "../styles/treePage.css";
import LoadingDots from "../components/general/LoadingDots";
import { Link } from "react-router-dom";
import Icon from "../components/general/Icon";

export interface Problem {
  id: number;

  title: string;
  type: "s" | "m" | "l";
  content: string;
  img?: string;
  note?: string;

  xPos: number;
  yPos: number;
  children: number[];

  state: number; // Whether or not the problem is unlocked
  /* 1: locked; 2: active; 3: solved */

  solution: string;
  season: number;
}

interface Props {
  problems: Problem[] | undefined;
}

// TODO: Add season selector
function TreePage({ problems }: Props) {

  const mapLines = () => {
    if (!problems) {return}
    return problems.map((p) => {
      let i = 0;
      return p.children.map((c) => {
        i++;

        const child: Problem = problems.find(
          (problem) => problem.id === c
        ) as Problem;
        if (!child) {
          return <></>;
        }

        return (
          <Line
            x1={p.xPos + 40 + "vw"}
            y1={p.yPos + "vh"}
            x2={child.xPos + 40 + "vw"}
            y2={child.yPos + "vh"}
            key={p.id + ":" + i}
          />
        );
      });
    });
  };

  const mapProblems = () => {
    if (!problems) {return}
    return problems.map((p) => <TreeNode problem={p} key={p.id} />);
  };

  const getSsnSelector = () => {
    if (!problems) {return}
    let seasons: number[] = []

    problems.forEach(p => {
      if (!seasons.includes(p.season)) {seasons.push(p.season)}
    })

    if (seasons.length > 1) {
      return <div className="seasonSelector">
      <h2>Ročník</h2>
      {seasons.map(s => <Link to={"/archiv?rocnik="+s}><button>{s}</button></Link>)}
      </div>
    }

    return <></>
  }

  return (
    <motion.div
      key="treePage"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
    >
      {problems ? (
        <>
          {!problems.length ? (
            <h2>Pro tento ročník nejsou úlohy dostupné</h2>
          ) : (
            <div className="treePage">

            <div style={{width: "80vw"}} className="centerAlign">
              <Link to="/vyhledat">
                <button className="row"><Icon name="search-sharp" color="#fff" size="2vw" /><span style={{marginLeft: "10px"}}>Vyhledat úlohu</span></button>
              </Link>
             </div> 

              {getSsnSelector()}
              <div className="treeContainer">
                <div className="lineContainer">
                  <svg width="80vw" height="140vh">
                    {problems && mapLines()}
                  </svg>
                </div>

                <div className="nodeContainer">{problems && mapProblems()}</div>
              </div>
            </div>
          )}
        </>
      ) : (
        <LoadingDots />
      )}
    </motion.div>
  );
}

export default TreePage;
