import {motion} from "framer-motion"
import { Problem } from "./TreePage";

import "../styles/problemInfoPage.css"
import LoadingDots from "../components/general/LoadingDots";
import { useEffect, useRef, useState } from "react";
import Icon from "../components/general/Icon";

interface Props {
    problem: Problem
}

function ProblemInfoPage({problem}: Props) {
    const getDesc = (text: string) => {
        if (text && text.length > 10000) {
            return text.slice(0,100000) + "..."
        } else if (!text || text === "") {
            return "xxx"
        } else {return text}
    }

    let [isOpen, setOpen] = useState(false)
    let [height, setHeight] = useState<number |undefined>(0)

    const ref = useRef<HTMLDivElement>(null);
 
    useEffect(() => {
      if (isOpen) setHeight((ref.current?.getBoundingClientRect().height || 100));
      else setHeight(0);
    }, [isOpen]);

    return ( 
        <motion.div
                key="problemPage"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: {duration: 1} }}
                exit={{ opacity: 0, transition: {duration: 0.3}}}> 

        {problem ? <>
            {problem.title !== "err" ? <div className="problemInfo"> {/*jesus christ*/}
                <h1>{`Úloha ${problem.id}: ${problem.title}`}</h1>
                <div className="desc">
                    {/* TODO: Add problem statistic */}
                    <h2>Zadání: </h2>

                    <div className="column">
                        <p dangerouslySetInnerHTML={{__html:getDesc(problem.content)}} style={{marginTop: "3.5vh"}}></p>
                        <p className="note">{problem.note}</p>
                        {problem.img && <img src={problem.img} alt={`Obrázek úlohy ${problem.title}`}/>}
                    </div>
                </div>

                <div className="desc">
                    {/*<h2>Řešení: </h2>

                    <p dangerouslySetInnerHTML={{__html:problem.solution}} style={{marginTop: "3.5vh"}}></p>*/}
                    
                    <div className="solutionRow">
                        <div className="solutionTitle row" onClick={() => {setOpen(!isOpen)}}>
                            <h2>{"Řešení"}</h2>
                            <Icon name={isOpen ? "caret-up-outline" : "caret-down-outline"} size={"2vw"} color="white"/>
                        </div>
                        <div className="dropdown" style={{height: height}}>
                            <div className="content" ref={ref}>
                            <p dangerouslySetInnerHTML={{ __html: problem.solution }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <h2>Úloha s tímto ID neexistuje</h2>}
        </> : <LoadingDots/>}

        </motion.div>  
     );
}

export default ProblemInfoPage;