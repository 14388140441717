import { BrowserRouter } from 'react-router-dom';
import { useSpecialClassHandler } from './scripts/useSpecialClassHandler';

import "./styles/main.css"
import "./styles/specialClasses.css"

import MainPage from './pages/MainPage';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDg_YWbnJJybry6kcsz9LMQk7eO_LZhCAo",
  authDomain: "smoulaarchiv.firebaseapp.com",
  projectId: "smoulaarchiv",
  storageBucket: "smoulaarchiv.firebasestorage.app",
  messagingSenderId: "791568887312",
  appId: "1:791568887312:web:9aef9e037c70f98fdc2495",
  measurementId: "G-3HFQTK2GS5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  useSpecialClassHandler()

  return (
    <BrowserRouter>
      <MainPage />
    </BrowserRouter>
  );
}

export default App;
